<template>
    <v-container
        class="pa-6 pt-md-16"
        fluid>
        <div class="def-page-cont">
            <h1 class="mb-6 mb-md-12">Your Account</h1>
            <v-row>
                <v-col
                    cols="12"
                    md="5">
                    <div class="profile-menu-wrap">
                        <profile-menu/>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="7">
                    <div class="receipts-wrap">
                        <h3 class="profile-ttl d-flex align-center justify-space-between mb-8 pb-6">
                            Receipts

                            <v-btn
                                v-if="selected.length"
                                outlined
                                rounded
                                width="137"
                                height="30"
                                @click="downloadHandler"
                                class="bg-primary main-text--text">
                                Download Selected
                            </v-btn>
                        </h3>
                        <div class="table-wrap pos-rel">
                            <div v-if="loading" class="page-preloader"/>

                            <v-data-table
                                v-model="selected"
                                :headers="headers"
                                :items="list"
                                :mobile-breakpoint="280"
                                hide-default-footer>
                                <template #item="{item, isSelected, select}">
                                    <tr :class="{'is-selected': isSelected}">
                                        <td>
                                            <simple-checkbox
                                                :value="isSelected"
                                                @input="select"
                                                :disabled="item.status !== 'completed'"
                                                class="justify-start"/>
                                        </td>
                                        <td>
                                            {{ item.date }}
                                        </td>
                                        <td>
                                            {{ item.id }}
                                        </td>
                                        <td>
                                            <div :class="`status status-${item.status} d-flex align-center justify-center text-uppercase`">
                                                {{ item.status }}
                                            </div>
                                        </td>
                                        <td>
                                            {{ item.amount }}
                                        </td>
                                        <td>
                                            {{ item.payment_method }}
                                        </td>
                                        <td>
                                            <v-btn width="30" height="30" icon @click="downloadHandler(item.id)"
                                                   :disabled="item.status !== 'completed'">
                                                <img :src="require('@/assets/icons/ic-download.svg')" alt="" width="16" height="16">
                                            </v-btn>
                                        </td>
                                    </tr>
                                    <tr class="spacer"><td style="height: 10px;"/></tr>
                                </template>
                            </v-data-table>

                            <div v-if="pagination.total_pages > 1" class="text-sm-center mt-4">
                                <v-pagination
                                    :value="pagination.current_page"
                                    :length="pagination.total_pages"
                                    :total-visible="6"
                                    class="pf-pagination"
                                    color="#515151"
                                    next-aria-label="Next"
                                    previous-aria-label="Previous"
                                    @input="changePage"/>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import ProfileMenu from "@/components/user-profile/ProfileMenu";
    import { userApi } from "@/api";
    import SimpleCheckbox from "@/components/ui/SimpleCheckbox";

    export default {
        name: "order-history",
        components: {
            ProfileMenu,
            SimpleCheckbox
        },
        data () {
            return {
                loading: false,
                list: [],
                selected: [],
                headers: [
                    { text: "", value: "select", sortable: false, width: "30px" },
                    { text: "Date", value: "date", sortable: false, width: "110px" },
                    { text: "Project ID", value: "id", sortable: false, width: "80px" },
                    { text: "Status", value: "status", sortable: false, width: "100px" },
                    { text: "Amount", value: "amount", sortable: false, width: "60px" },
                    { text: "Payment Method", value: "payment_method", sortable: false, width: "100px" },
                    { text: "Action", value: "action", sortable: false, width: "30px" }
                ],
                pagination: {
                    count: null,
                    current_page: null,
                    links: null,
                    per_page: null,
                    total: null,
                    total_pages: null
                }
            };
        },
        created () {
            this.getInfo();
        },
        methods: {
            async queryReplaceHandler (query) {
                await this.$router.push({
                    query: {
                        ...this.$route.query,
                        "page": query
                    }
                });
            },
            async getInfo () {
                this.loading = true;
                this.selected = [];

                const params = { page: Math.abs(this.$route.query.page) || 1 };

                try {
                    const { data } = await userApi.getReceipts(params)
                    this.pagination = data.meta.pagination;
                    this.list = data.data;
                } catch (error) {
                    console.error(error)
                } finally {
                    this.loading = false;
                }
            },
            async changePage (page) {
                if (this.pagination.current_page !== page) {
                    await this.queryReplaceHandler(page);
                    await this.getInfo();
                }
            },
            async downloadHandler(receipt_id = null) {
                let receipts_ids = [receipt_id];
                if (typeof receipt_id !== "number") {
                    receipts_ids = this.selected.map(receipt => receipt.id);
                }

                const { data } = await userApi.downloadReceipts(receipts_ids);
                this.forceDownload(data, receipts_ids);
            },
            forceDownload(data, receipts) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", receipts.length > 1 ? "Receipts.zip" : "Receipt.pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
</script>

<style lang="scss" scoped>
.profile-menu-wrap {
    position: sticky;
    top: 10px;
    @media screen and (min-width: 960px) {
        padding-right: 15px;
    }
}
.receipts-wrap {
    padding: 24px 20px 20px;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 12px;

    .profile-ttl {
        font-size: 24px;
        line-height: 30px;
        color: #464646;
        padding-bottom: 8px;
        border-bottom: 1px solid #E7E7E7;
        font-weight: 500;
    }

    @media screen and (max-width: 959px) {
        margin-top: 24px;
        padding: 24px 16px 20px;
    }
}

.table-wrap {
    min-height: 200px;

    ::v-deep .v-data-table {
        thead tr th {
            padding: 0 10px;
            border-bottom: none!important;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: #777777;

            &:first-child {
                padding: 0;
            }
        }

        tbody tr {
            &.is-selected,
            &:hover {
                background-color: transparent!important;
                td:not(:first-child) {
                    background-color: #F0FFF3;
                    border-color: #3F7359!important;
                }
            }

            td {
                padding: 0 10px;
                font-size: 14px;
                color: #464646;
                border-top: 1px solid;
                border-color: #BCBCBC!important;

                &:first-child {
                    border: none!important;
                    padding: 0;
                }

                &:nth-child(2) {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    border-left: 1px solid;
                }

                &:last-child {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-right: 1px solid;
                }
            }
        }

        .status {
            width: 100px;
            height: 24px;
            font-size: 12px;
            border: 1px solid #464646;
            border-radius: 4px;
            background-color: #F0FFF3;

            &.status-awaiting {
                background-color: #FFFDCC;
            }
        }

        .v-btn--disabled,
        .v-simple-checkbox--disabled {
            opacity: .5;
        }
    }
}
</style>
